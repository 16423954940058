




















































































































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';

.box-table {
  // border-top: 5px solid $colorPrimary;
  // border-top-left-radius: 0 !important;
  // border-top-right-radius: 0 !important;

  .v-data-table-header-mobile {
    display: none;
  }

  &.box-table-ganhadores {
   .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      // padding: 15px 0;
    } 

    .v-data-table tbody tr.v-data-table__expanded__content {
      box-shadow: none;
    }
  }

  .box-table-palpites {
    .v-data-table {
      margin: 20px 0
    }
  } 
}
